// packages
import React, { useState } from "react";
import styled from "styled-components";
// components
import { Link } from "gatsby";
// media
import BaseBlackYetiLogo from "../images/svgs/black-yeti-logo.svg";
import BaseBYIcon from "../images/svgs/b-y-icon.svg";
import BaseMenuIcon from "../images/svgs/menu-icon.svg";
import PolitikLogo from "../images/politik_logo.png";
// constants
import { PAGES } from "../constants";
// functions
import { mediaQuery } from "./Theme";

const NavBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100vw;
  height: 5rem;
  padding: 1rem 2rem;
  z-index: 99;
  background-color: ${props =>
    props.inverse === "inverse"
      ? `#000000`
      : props.page === PAGES.POLITIK_PAGE
      ? props.theme.colors.politikWhite
      : `#ffffff`};

  ${mediaQuery("flex-direction", "", [{ 1024: "column" }])};
  ${mediaQuery("width", "rem", [{ 1024: 13 }])};
  ${mediaQuery("height", "vh", [{ 1024: 100 }])};
  ${mediaQuery("padding", "rem", [{ 1024: 1 }])};
`;

const LogoLink = styled(Link)`
  ${mediaQuery("margin-top", "rem", [{ 1024: 1 }])};
`;

const BlackYetiLogo = styled(BaseBlackYetiLogo)`
  display: none;
  fill: ${props =>
    props.page === PAGES.POLITIK_PAGE ? props.theme.colors.politikBlack : `#000000`};

  ${mediaQuery("display", "", [{ 1024: "block" }])};
  ${mediaQuery("width", "rem", [{ 1024: 9 }])};
`;

const BYIcon = styled(BaseBYIcon)`
  fill: ${props => (props.inverse === "inverse" ? `#ffffff` : `#000000`)};
  height: 2.5rem;

  ${mediaQuery("display", "", [{ 1024: "none" }])};
`;

const MenuIcon = styled(BaseMenuIcon)`
  fill: ${props => (props.inverse === "inverse" ? `#ffffff` : `#000000`)};
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;

  ${mediaQuery("display", "", [{ 1024: "none" }])};
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 5rem;
  left: 0;
  width: 100vw;
  height: 5rem;
  background-color: #000000;
  padding: 1rem 2rem;
`;

const MobileSiteLink = styled(Link)`
  width: fit-content;
  font-family: ${props => props.theme.fonts.primary};
  font-size: 1rem;
  font-weight: normal;
  text-decoration: none;
  color: #ffffff;
`;

const LinkContainer = styled.div`
  display: none;

  ${mediaQuery("display", "", [{ 1024: "flex" }])};
  ${mediaQuery("flex-direction", "", [{ 1024: "column" }])};
  ${mediaQuery("height", "%", [{ 1024: 100 }])};
  ${mediaQuery("margin-left", "rem", [{ 1024: 0.5 }])};
  ${mediaQuery("margin-top", "rem", [{ 1024: 2 }])};
`;

const DesktopSiteLink = styled(Link)`
  display: none;
  width: fit-content;
  font-family: ${props => props.theme.fonts.primary};
  font-size: 1rem;
  font-weight: normal;
  text-decoration: none;
  color: #000000;
  margin-right: 0;
  padding: 0 0.5rem;
  margin-bottom: 1rem;
  &:hover {
    box-shadow: 0px 2px ${props => props.theme.colors.iceBlue};
  }

  ${mediaQuery("display", "", [{ 1024: "block" }])};
`;

const PolitikLink = styled(Link)`
  width: fit-content;
  padding: 0 0.5rem;
  margin-bottom: 1rem;
  &:hover {
    box-shadow: 0px 2px ${props => props.theme.colors.iceBlue};
  }

  ${mediaQuery("display", "", [{ 1024: "block" }])};
`;

const StyledPolitikLogo = styled.img`
  height: 1.25rem;
`;

const CopyrightText = styled.span`
  position: fixed;
  left: 2rem;
  bottom: 1rem;
  font-family: ${props => props.theme.fonts.primary};
  font-size: 0.5rem;
  line-height: 0.5rem;
  padding: 0 0.5rem;
`;

const NavBar = ({ page }) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <NavBarContainer inverse={showMenu ? "inverse" : null} page={page}>
      <LogoLink to="/">
        <BYIcon inverse={showMenu ? "inverse" : null} />
        <BlackYetiLogo page={page} />
      </LogoLink>
      <MenuIcon inverse={showMenu ? "inverse" : null} onClick={() => setShowMenu(!showMenu)} />
      {showMenu && (
        <MenuContainer>
          <MobileSiteLink to="/about-black-yeti">about black yeti</MobileSiteLink>
          {/* <MobileSiteLink to="/designer-insights">designer insights</MobileSiteLink> */}
          <MobileSiteLink to="/politik">POLITIK</MobileSiteLink>
          {/* <MobileSiteLink to="/shop">shop</MobileSiteLink> */}
        </MenuContainer>
      )}
      <LinkContainer>
        <DesktopSiteLink to="/about-black-yeti">about black yeti</DesktopSiteLink>
        {/* <DesktopSiteLink to="/designer-insights">designer insights</DesktopSiteLink> */}
        <PolitikLink to="/politik">
          <StyledPolitikLogo src={PolitikLogo} />
        </PolitikLink>
        {/* <DesktopSiteLink to="/shop">shop</DesktopSiteLink> */}
        <CopyrightText>© Black Yeti Games 2022</CopyrightText>
      </LinkContainer>
    </NavBarContainer>
  );
};

export default NavBar;
